<template>
  <div
    class="grid grid-cols-1 grid-rows-[minmax(0,_var(--main-height))] bg-lexmea-gray-500 lg:overflow-hidden"
    :class="{
      'lg-grid-template-with-by-searchbar': !showcase,
    }"
  >
    <nav
      v-if="!showcase"
      class="hidden h-full flex-col items-center gap-5 bg-lexmea-blue-400 pr-5 pt-12 text-white lg:flex"
    >
      <a
        v-for="(category, index) in categories"
        :key="index"
        :href="category.href"
        class="flex w-full items-center justify-start gap-5 rounded-r py-7 pl-4 pr-8 transition hover:bg-lexmea-pop-darker"
        :class="{
          'bg-lexmea-pop': category.href === pageContext.urlPathname,
        }"
      >
        <div>
          <component
            :is="category.icon"
            class="h-10 w-10 fill-current stroke-current text-[#c2cfd6]"
          />
        </div>
        <span class="text-xl font-semibold text-lexmea-gray-1000">{{
          category.title
        }}</span>
      </a>
    </nav>

    <div
      ref="libraryContainer"
      class="library-container-background flex flex-col gap-4 overflow-y-auto px-3 pb-4 lg:px-6"
    >
      <template v-if="!showcase">
        <CardHeader
          :content="$t('bookshelf.tooltips.title')"
          class="justify-center pt-4 text-2xl font-bold text-lexmea-blue-500"
          blueIcon
        >
          <h1 class="text-12pt leading-8">{{ $t("library") }}</h1>
        </CardHeader>
        <ContentHeader v-if="user" :user="user" />

        <MobileNav
          class="sticky inset-x-0 top-0 z-10 lg:hidden"
          :activeItem="activeItem"
          :navItems="categories"
        />
      </template>
      <component :is="innerView" />
      <Transition
        enterFromClass="translate-x-[200%] opacity-0"
        leaveToClass="translate-x-[200%] opacity-0"
        enterActiveClass="transition duration-300"
        leaveActiveClass="transition duration-300"
      >
        <GoUpFAB v-if="showGoUpButton" :container="libraryContainer" />
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = useRouteParams<{
  view: "chronik" | "inhalte" | "law";
}>();

const pageContext = usePageContext();

const ChronikOverview = defineAsyncComponent(
  () => import("~/components/library/chronik/ChronikOverview.vue")
);
const LawOverview = defineAsyncComponent(
  () => import("~/components/library/law/LawOverview.vue")
);
const UserContentOverview = defineAsyncComponent(
  () => import("~/components/library/usercontent/UserContentOverview.vue")
);

const innerView = computed(() => {
  switch (props.view) {
    case "inhalte":
      return UserContentOverview;
    case "chronik":
      return ChronikOverview;
    default:
      return LawOverview;
  }
});

const showcase = useExamShowcase();

const { user } = storeToRefs(useUserStore());

const categories = useLibraryTabs();

const activeItem = useArrayFindIndex(categories, (category) => {
  return category.href === pageContext.urlPathname;
});

const libraryContainer = ref<HTMLElement | null>(null);
const { y } = useScroll(libraryContainer);
const showGoUpButton = ref(false);

watch(
  () => y.value,
  () => {
    const threshold = window.innerHeight * 0.2;
    showGoUpButton.value = y.value > threshold;
  }
);
const { navbarWidth } = useNavbarWidthStore();

const { setLastSubPagePersisted } = useLibrarySubPagePersisted();
const isMounted = useMounted();
watchArray(
  () => [isMounted.value, pageContext.urlPathname],
  () => setLastSubPagePersisted(pageContext.urlPathname),
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.lg-grid-template-with-by-searchbar {
  @media (min-width: 1024px) {
    grid-template-columns: v-bind(navbarWidth) 1fr;
  }
}

.library-container-background {
  @screen lg {
    background: url("~/assets/library-bg-shape.svg") no-repeat center;
    background-size: 80%;
  }
  @apply bg-lexmea-gray-500;
}
</style>
